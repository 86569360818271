'use strict';

module.exports = {};

// 2 - make it so that we can have more than 1 multiselect on a page - prefixes?
// 3 - but use the same data to populate it! - eg. source;
// 4 - allow a series of id's to populate a drop down.

module.exports.data = {};
module.exports.map = {};

module.exports.load_category = function load_category(
	parent_id,
	target,
	cb,
	cb2
) {
	var load = {
		url: '/ajax/category',
		data: { id: parent_id },
		method: 'POST',
		success: function (data) {
			if (data.success) {
				module.exports.map[parent_id.toString()] = data.children;
			}
			if (Object.keys(data.children).length > 0)
				cb(target, data.children, cb2);
			else if (typeof cb2 === 'function') cb2();
		},
		error: function (e, data) {
			console.log(e, data);
		}
	};

	$.ajax(load);
};

module.exports.disable = function disable(myselect) {
	document.getElementById(myselect).disabled = true;
};

module.exports.enable = function enable(myselect) {
	document.getElementById(myselect).disabled = false;
};

module.exports.clear = function clear(menu) {
	var n = menu.options.length;
	var i;

	for (i = n; i >= 0; i--) {
		menu.options[i] = null;
	}
};

module.exports.newoption = function newoption(txt, val) {
	//	alert('New Option: ' + txt + ' ' + val);
	var newelem = new Option(txt, val);
	return newelem;
};

module.exports.blank = function blank(menu) {
	module.exports.clear(menu);
	menu.options[0] = module.exports.newoption('', 0);
};

module.exports.submenu = function submenu(menu, list, cb) {
	if (Object.keys(list).length === 0) {
		menu.disabled = true;
		menu.options[0] = module.exports.newoption(' ', 0);
		if (typeof cb === 'function') {
			cb();
		}
		return;
	}

	var i = 0;
	var k;

	menu.options[i] = module.exports.newoption('[ select ]', -1);
	for (k in list) {
		i++;
		menu.options[i] = module.exports.newoption(k, list[k]);
	}
	if (i > 1) menu.disabled = false;
	if (typeof cb === 'function') {
		cb();
	}
};

module.exports.populate = function populate(name, level, cb) {
	var current = name + '_lvl_' + level;
	var target = name + '_lvl_' + parseInt(level + 1, 10);

	var targetMenu = document.getElementById(target);
	var currentMenu = document.getElementById(current);

	var ix = currentMenu.selectedIndex;
	if (ix === -1) return;

	var val = currentMenu[ix].value;

	if (module.exports.map[val]) {
		module.exports.submenu(targetMenu, module.exports.map[val], cb);
	} else {
		module.exports.load_category(
			val,
			targetMenu,
			module.exports.submenu,
			cb
		);
	}
};

module.exports.auto_select = function auto_select(name, level, value) {
	// This function takes a value and selects it
	var menu = document.getElementById(name + '_lvl_' + level);

	var n = menu.options.length - 1;
	var i;

	for (i = n; i >= 0; i--) {
		if (menu.options[i].value === value) {
			menu.selectedIndex = i;
			return;
		}
	}
};

module.exports.build_select = function build_select(name, i, locations) {
	if (locations[i] === undefined) return;
	if (i >= locations.length) return;

	var cb = function () {
		module.exports.build_select(name, i + 1, locations);
	};

	module.exports.auto_select(name, i, locations[i]);
	module.exports.populate(name, i, cb);
};

module.exports.blank_all = function blank_all(name, level) {
	//alert('Blank all: ' + name + ' ' + level);
	var n = module.exports.data[name].depth;
	while (n > level) {
		var element = '' + name + '_lvl_' + n;
		module.exports.blank(document.getElementById(element));
		module.exports.disable(element);
		n--;
	}
};
