"use strict";

// Forum related js.


module.exports = {};

module.exports.forum_reply_to = function forum_reply_to(post) {
  // looks for the child form and puts [replyto=123] in the contents
  var reply = $('#response_iframe').contents().find('#reply_field');
  var nl = '';
  if ($(reply).val().length > 0) {
    nl = "\n";
  }
  $(reply).append(nl + "[replyto=" + post + "] ");
  $(reply).focus();
  window.location = '#reply_form';
  return false;
};


module.exports.forum_goto_post = function forum_goto_post(post) {
  // looks for a name='post_$post' in the current page - if not - redirects to post
  var mypost = 'apost_' + post;
  var id = document.getElementById(mypost);
  if (id === null) {
    window.location = '/utils/goto?post_id=' + post;
    return false;
  }
  // the #post_123 is in the link - so works in the other case fine...
};



module.exports.init = function() {

};

module.exports.ready = function() {

  $('.forum-reply-to').on('click', function(e) {
    e.preventDefault();
    console.log(this);
    var replyId = $(this).data('reply-to');
    module.exports.forum_reply_to(replyId);
  });

  var to_summary_open;
  $('a.forum_summary_head.sticky').on('mouseover', function() {
    var topic = $(this).attr("id");
    var n = topic.replace('forum_summary_title_', '');
    var summary = $('div#forum_summary_body_' + n);
    to_summary_open = setTimeout(function() {
      summary.addClass('open', 500);
    }, 650);
  });

  $('#quick-reply-button').on('click', function(e) {
    if ($(this).hasClass('form-submitted')) {
      e.preventDefault();
      return;
    }
    $(this).addClass('form-submitted');
    $(this).parent('form').submit();
  });

  $('a.forum_summary_head.sticky').on('mouseleave', function() {
    clearTimeout(to_summary_open);
  });


};

module.exports.interacted = function() {

};
