"use strict";

module.exports = {};

var text_digits = function text_digits(num, digits) {

  if (! digits) digits = 2; // default to 00 notation
  var html = '', power, digit;

  while (digits) {
    digits = digits -1;
    power = Math.pow(10,digits);
    digit = Math.floor(num / power);

    html += digit;
    num = num - digit*power;
  }

  return html;
};

module.exports.text_digits = text_digits;

module.exports.countdown = function countdown(year, month, day, hour, minute, format, text) {

  console.log('COUNTDOWN', arguments);

  var clock_name = 'countdown_'+year+month+day+hour+minute;
  var clock = document.getElementById(clock_name);

  var Today = new Date();
  var Todays_Year = Today.getFullYear() - 2000;
  var Todays_Month = Today.getMonth() + 1;

  //Convert both today's date and the target date into miliseconds.
  var Todays_Date = (new Date(Todays_Year, Todays_Month, Today.getDate(),
        Today.getHours(), Today.getMinutes(), Today.getSeconds())).getTime();

  var Target_Date = (new Date(year, month, day, hour, minute, 0)).getTime();

  //Find their difference, and convert that into seconds.
  var Time_Left = Math.round((Target_Date - Todays_Date) / 1000);

  if(Time_Left < 0) Time_Left = 0;

  // Styelised text.
  var days = Math.floor(Time_Left / (60 * 60 * 24));
  Time_Left %= (60 * 60 * 24);
  var hours = Math.floor(Time_Left / (60 * 60));
  Time_Left %= (60 * 60);
  var minutes = Math.floor(Time_Left / 60);
  Time_Left %= 60;
  var seconds = Time_Left;

  var dps = 's', hps = 's', mps = 's', sps = 's';

  //ps is short for plural suffix.
  if (days === 1) dps ='';
  if (hours === 1) hps ='';
  if (minutes === 1) mps ='';
  if (seconds === 1) sps ='';

  clock.innerHTML = "&nbsp;&nbsp;";

  var s_days, daydigits;

  // Now get some images...
  if (days) {
    s_days = String(days);
    daydigits = s_days.length;
    clock.innerHTML += text_digits(days,daydigits) +" Day" + dps +" ";
  }
  clock.innerHTML += text_digits(hours,2) + ':' + text_digits(minutes,2) + ":" + text_digits(seconds,2) + "&nbsp;&nbsp;" + text;

  //Recursive call, keeps the clock ticking.
  setTimeout('countdown("' + year + '","' + month + '","' + day + '","' + hour + '","' + minute + '",' + format +',"' + text + '");', 1000);
};


module.exports.countup = function countup(year, month, day, hour, minute, format, text) {

  var clock_name = 'countup_'+year+month+day+hour+minute;
  //	document.getElementById("debug").innerHTML = clock_name;
  var clock = document.getElementById(clock_name);
  //	var debug_name = 'debug_'+year+month+day+hour+minute;
  //	var debug = document.getElementById(debug_name);


  var Today = new Date();
  var Todays_Year = Today.getFullYear();
  var Todays_Month = Today.getMonth() + 1;

  //Convert both today's date and the target date into miliseconds.
  var Todays_Date = (new Date(Todays_Year, Todays_Month, Today.getDate(),
        Today.getHours(), Today.getMinutes(), Today.getSeconds())).getTime();
  var Target_Date = (new Date(year, month, day, hour, minute, 0)).getTime();
  //debug.innerHTML = Target_Date +' vs '+Todays_Date ;

  //Find their difference, and convert that into seconds.
  var Time = Math.round((Todays_Date - Target_Date) / 1000);

  var days = Math.floor(Time / (60 * 60 * 24));
  Time %= (60 * 60 * 24);
  var hours = Math.floor(Time / (60 * 60));
  Time %= (60 * 60);
  var minutes = Math.floor(Time / 60);
  Time %= 60;
  var seconds = Time;

  var dps = 's', hps = 's', mps = 's', sps = 's';

  //ps is short for plural suffix.
  if (days === 1)    dps ='';
  if (hours === 1)   hps ='';
  if (minutes === 1) mps ='';
  if (seconds === 1) sps ='';

  clock.innerHTML = "&nbsp;&nbsp;";

  var s_days, daydigits;

  if (days) {
    s_days = String(days);
    daydigits = s_days.length;
    clock.innerHTML += text_digits(days,daydigits) +" Day" + dps +" ";
  }
  clock.innerHTML += text_digits(hours,2) + ':' + text_digits(minutes,2) + ":" + text_digits(seconds,2) + "&nbsp;&nbsp;" + text;

  //Recursive call, keeps the clock ticking.
  setTimeout('countup("' + year + '","' + month + '","' + day + '","' + hour + '","' + minute + '",' + format +',"' + text + '");', 1000);
};

