"use strict";

$.fn.extend({
  digits : function(num, digits) {

    if (! digits) {digits = 2;}
    var html = '';
    while (digits) {
      digits = digits -1;
      var power = Math.pow(10,digits);
      var digit = Math.floor(num / power);
      html += digit;
      num = num - digit*power;
    }

    return html;
  },
  counter : function (obj,countdown) {

    var Today = new Date();
    var n_year  = Today.getFullYear();
    var n_month  = Today.getMonth() + 1;
    var n_day = Today.getDate();
    var n_hour = Today.getHours();
    var n_min = Today.getMinutes();
    var n_sec = Today.getSeconds();

    var now = (new Date(n_year, n_month, n_day, n_hour, n_min, n_sec)).getTime();

    var target = (new Date($(obj).attr('data-year'), $(obj).attr('data-month'),$(obj).attr('data-day'),$(obj).attr('data-hour'), $(obj).attr('data-min'), 0)).getTime();

    //Find their difference, and convert that into seconds.
    var time = 0;
    if (countdown) {
      time = Math.round((target - now) / 1000);
      if (time < 0) time = 0;
    } else time = Math.round((now - target) / 1000);

    if ($(obj).find('.months').length > 0) {
      var months = Math.floor(time / (60 * 60 * 24 * 30));
      time %= (60 * 60 * 24 * 30);
      $(obj).find('.months').html( String(months) );
    }

    if ($(obj).find('.days').length > 0) {
      var days = Math.floor(time / (60 * 60 * 24));
      time %= (60 * 60 * 24);
      $(obj).find('.days').html( String(days) );
    }

    if ($(obj).find('.hours').length > 0) {
      var hours = Math.floor(time / (60 * 60));
      time %= (60 * 60);
      $(obj).find('.hours').html( $.fn.digits(String(hours)) );
    }

    if ($(obj).find('.minutes').length > 0) {
      var minutes = Math.floor(time / 60);
      time %= 60;
      $(obj).find('.minutes').html( $.fn.digits(String(minutes)) );
    }

    if ($(obj).find('.seconds').length > 0) {
      var seconds = time;
      $(obj).find('.seconds').html( $.fn.digits(String(seconds)) );
    }

    //Recursive call, keeps the clock ticking.
    setTimeout(function(){$.fn.counter(obj,countdown)}, 1000);
  }
});

