'use strict';

module.exports = {};

var last_bg_style = null;

module.exports.highlight = function (id) {
  var obj = document.getElementById('cms_' + id);
  last_bg_style = obj.style.backgroundColor;
  obj.style.backgroundColor = '#eef';
};

module.exports.unhighlight = function (id) {
  var obj = document.getElementById('cms_' + id);
  obj.style.backgroundColor = null;
};

module.exports.dialog = function (url, windowname, width, height, features) {
  width = width ? width : screen.width / 2;
  height = height ? height : screen.height / 2;
  var screenX = screen.width / 2 - width / 2;
  var screenY = screen.height / 2 - height / 2;
  features =
    'width=' +
    width +
    ',height=' +
    height +
    'toolbar=no,menubar=no,scrollbars=yes,resizable=yes,status=no,location=no,directories=no,copyhistory=no';
  features += ',screenX=' + screenX + ',left=' + screenX;
  features += ',screenY=' + screenY + ',top=' + screenY;

  var mywin = window.open(url, windowname, features);
  if (mywin) mywin.focus();
  return false;
};

module.exports.edit_link = function (key, required_trust, id) {
  var trust = $.cookie('cms_trust');
  var missing;

  if (parseInt(trust) >= parseInt(required_trust)) {
    if (document.getElementById('cms_missing_' + id) !== null) {
      missing = document.getElementById('cms_missing_' + id);
      missing.style.visibility = 'visible';
    }
    var block = document.getElementById('cms_' + id);
    if (block !== null) {
      var str =
        " <a href='/' onmouseover='cms_highlight_span(\"" +
        id +
        '")\' onmouseout=\'cms_unhighlight_span("' +
        id +
        '")\' onclick=\'dialog("/admin/content-block-pop?' +
        key +
        "\",\"translation\",810,875); return false;' rel='nofollow' class='cms-edit'>edit</a> ";
      block.innerHTML += str;
    }
  }
};

module.exports.load_js = function (src) {
  // Non ajax - as allows caching of results (headers set properly)

  if (document.createElement && document.childNodes) {
    var new_script = document.createElement('script');
    new_script.setAttribute('src', src);
    new_script.setAttribute('type', 'text/javascript');
    document.getElementsByTagName('head')[0].appendChild(new_script);
  } else {
    var ext_js = document.getElementById('ext_js');
    /*jslint evil:true */
    if (ext_js) ext_js.src = src;
    else
      document.write(
        '<script src="' + src + '" type="text/javascript"></script>'
      );
  }
};

module.exports.age = function (utime) {
  // assumption made that the client time is correct... hmm
  var span = document.getElementById('cms_time');
  if (typeof span !== 'object') return 0;
  if (utime < 1) return;
  var now = module.exports.now();
  var age = now - utime;
  if (age < 0) age = 0;
  var age_text = '';
  if (age < 600) age_text = 'Age:' + age + 's';
  else if (age >= 6000) {
    var hours = age / 60 / 60;
    age_text = 'Age:' + Math.floor(hours) + 'h';
  } else if (age >= 600) {
    var mins = age / 60;
    age_text = 'Age:' + Math.floor(mins) + 'm';
  }

  span.innerHTML += age_text;
};

module.exports.now = function () {
  var today = new Date();
  var now = Math.round(today.getTime() / 1000.0);
  return now;
};

module.exports.timestamp_2_time = function (timestamp) {
  var datetime = new Date(timestamp * 1000);
  return datetime;
};

module.exports.edit_links = function edit_links() {
  $('span.cms_edit_span').each(function () {
    var id = $(this).data('id');
    if (!id) return;

    var trust = parseInt($(this).data('trust'));
    var ourtrust = parseInt($.cookie('cms_trust'));

    if (isNaN(ourtrust)) return;
    if (ourtrust < trust) return;

    $(this).html(
      '<a id="cmslink_' + id + '" class="cms_edit" href="#">edit</a>'
    ); // icon?

    $('a#cmslink_' + id).on(
      'mouseover mouseout mouseleave mouseenter click',
      function (event) {
        if (event.type === 'mouseover' || event.type === 'mouseenter')
          module.exports.highlight(id);
        else if (event.type === 'click') {
          // here instead of click do some kind of inline ajaxy editing.. coooll
          module.exports.dialog(
            '/admin/content-block-pop?id=' + id,
            'translation',
            810,
            875
          );
          return false;
        } else module.exports.unhighlight(id);
      }
    );

    $('span#cms_missing_' + id).css({ visibility: 'visible' });
  });
};

module.exports.interacted = function () {
  module.exports.edit_links();
};
