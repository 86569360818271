"use strict";

/* global $:true, window:true */
if (typeof window.jQuery === 'undefined') var $ = window.jQuery = window.$ = require('jquery')

// vendor jquery plugins.
require('./vendor/jquery.lazyload');
require('./vendor/jquery.cookie');
require('./vendor/jquery/jrumble.1.1');
require('./vendor/jquery/md5.jq');
require('./vendor/jquery/tablesorter/jquery.tablesorter');
require('./vendor/jquery/tablesorter/jquery.tablesorter.widgets');

require('./src/jquery-modules'); // custom jquery
