"use strict";

// Utility functions.

module.exports = {};

module.exports.strpos = function strpos(haystack, needle, offset) {
  // pseudo php
  var i = (haystack + '').indexOf(needle, (offset || 0));
  return i === -1 ? false : i;
};

module.exports.strlastpos = function strlastpos(haystack, needle) {
  // pseudo php
  var i = (haystack + '').lastIndexOf(needle);
  return i === -1 ? false : i;
};



/**
 * Decimal adjustment of a number.
 *
 * @param	{String}	type	The type of adjustment.
 * @param	{Number}	value	The number.
 * @param	{Integer}	exp		The exponent (the 10 logarithm of the adjustment base).
 * @returns	{Number}			The adjusted value.
 */
function decimalAdjust(type, value, exp) {
  // If the exp is undefined or zero...
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value);
  }
  value = +value;
  exp = +exp;
  // If the value is not a number or the exp is not an integer...
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN;
  }
  // Shift
  value = value.toString().split('e');
  value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
  // Shift back
  value = value.toString().split('e');
  return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
}

// augmented Math (under _app.utils.Math)
module.exports.Math = {
  round10 : function(value, exp) {
		return decimalAdjust('round', value, exp);
	},
  floor10 : function(value, exp) {
  	return decimalAdjust('floor', value, exp);
	},
  ceil10 : function(value, exp) {
			return decimalAdjust('ceil', value, exp);
	}
};



module.exports.getQueryParams = function getQueryParams(qs) {
  qs = qs.split("+").join(" ");

  var params = {};
  var re = /[?&]?([^=]+)=([^&]*)/g;

  var tokens = re.exec(qs);
  while (tokens) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    tokens = re.exec(qs);
  }

  return params;
};


module.exports.parseURL = function parseURL() {
  var url = window.document.location.href;
  var qspos = url.indexOf('?');
  var qs = false;
  var params = {};

  if (qspos) {
    qs =  url.substr(qspos + 1);
    url = url.substr(0,qspos);
    params = module.exports.getQueryParams(qs);
  }

  var pos = module.exports.strpos(url,'/',8);
  var lpos = module.exports.strlastpos(url,'/');
  var file = url.substr(lpos + 1); // drop last /
  var dir = url.substr(pos,lpos - pos + 1);

  pos = module.exports.strpos(dir,'/',1);
  var section = dir.substr(1,pos-1);

  var host = document.location.hostname;
  var port = false;
  if (document.location.port !== '') port = document.location.port;

  return {url:url, host:host, port:port, dir:dir, file:file, qs:qs, section:section, params: params};
};


module.exports.info = module.exports.parseURL();

module.exports.getCookie = function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
    var c = ca[i];
    while (c.charAt(0)===' ') c = c.substring(1,c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
};

module.exports.deleteCookie = function deleteCookie(name) {
  $.cookie(name,null,{domain: 'travelblog.org'});
  var info = module.exports.info;
  if (info.port) $.cookie(name,null,{domain: info.domain,path: '/',port :info.port});
};


module.exports.showCookies = function showCookies() {
  console.log("++++++++++++++ LIST COOKIES +++++++++++++++");
  var ca = document.cookie.split(';');
  console.log(ca);
  for(var i=0;i < ca.length;i++) {
    var c = ca[i];
    while (c.charAt(0)===' ') c = c.substring(1,c.length);
    console.log(c);
  }
  console.log("++++++++++++ FINISHED COOKIES +++++++++++++");
};

module.exports.clearCookies = function clearCookies() {
  // Caveat - only clears cookies that are set by js.
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
    var c = ca[i];
    while (c.charAt(0)===' ') c = c.substring(1,c.length);
    var ck = c.split('=');
    module.exports.deleteCookie(ck[0]);
  }
};

