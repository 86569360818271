'use strict';

module.exports = {};

module.exports.process_results = function process_results(encoded_data) {
  var lookup;
  if ($('#join_form').hasClass('form-fb-register')) {
    lookup = { nick: 'Nickname' };
  } else {
    lookup = {
      email: 'Email',
      email2: 'Confirm Email',
      name: 'Full Name',
      nick: 'Nickname'
    };
  }
  var data;
  if (typeof encoded_data === 'object') data = encoded_data;
  else data = $.parseJSON(encoded_data);

  if (!data.success) {
    if (!data.error) {
      $('#error_message').html('There has been an error').removeClass('hide');
      return;
    }
    var message = '';
    $.each(data.error, function (i, v) {
      $('.form-group.' + i).addClass('has-error');
      message += '<br>&bull; <b>' + lookup[i] + '</b> : ' + v + '  ';
    });

    $('#error_message')
      .html('There has been an error - please check the form. ' + message)
      .removeClass('hide');
  }

  if (data.success === true) {
    var content =
      "<h1 class='tb bar'>Thank you for joining Travel Blog</h1><div class='callout callout-success'><h4>Welcome to Travel Blog!</h4><br>" +
      "<br>An email is on it's way to you now - it might take 10 minutes - after that please double check any junk folders.</div>";
    $('div.content').html(content);
    var label = 'web';
    if (data.invite) label = 'invite';
    if (window._gaq)
      window._gaq.push([
        '_trackEvent',
        'conversion',
        'join',
        label,
        data.blogger
      ]);
  }
};

module.exports.submit_form = function submit_form(form_name) {
  $('#error_message').addClass('hide');

  var pass_test = true;
  var cdata = { error: {} };
  $('form#' + form_name + ' input').each(function () {
    if ($(this).val() === '') {
      cdata.error[$(this).attr('name')] = ' missing ';
      pass_test = false;
    }
  });

  if (!pass_test) {
    module.exports.process_results(cdata);
    return false;
  }

  if (!$('#join_bc').length) {
    var captcha = grecaptcha.getResponse();
    if (!captcha) {
      $('#error_message')
        .html(
          'There has been an error - please check the "I am not a robot" box.'
        )
        .removeClass('hide');
      return false;
    }
  }

  $('#join_submit').val('Please wait...');
  $('#join_submit').attr('disabled', true);

  var data = $('form#join_form').serialize();
  console.log({ message: 'preparing to create account', data: data });
  $.ajax({
    type: 'POST',
    url: '/ajax/join',
    data: data,
    success: function (results) {
      $('#join_submit').val('Join Travel Blog');
      $('#join_submit').attr('disabled', false);
      module.exports.process_results(results);
    },
    error: console.error,
    dataType: 'json'
  });
};

module.exports.nick_nonce = function nick_nonce() {
  var data = { string: $('#join_nick').val() };

  $.ajax({
    type: 'GET',
    url: '/ajax/nonce',
    data: data,
    success: function (res) {
      $('#form_nonce').val(res);
    }
  });
};

module.exports.ready = function () {
  $('input#join_submit').on('click', function () {
    return module.exports.submit_form('join_form');
  });

  // ajax get nonce

  //  $('#join_nick').change(function() {
  //		nick_nonce();
  //	});

  //	nick_nonce();
};

// jquery stuff for the join form.
$('#join_name').on('keypress keyup focus', function () {
  var name = $('#join_name').val();

  $('.form-group.name').removeClass('has-error');
  name = name.replace(/[<>\[\]]/g, '');

  $('#join_name').val(name);
  if (name.length > 3) {
    $('#join_name').addClass('tick');
    $('#join_name').removeClass('cross');
    return;
  }
});

var nick_check_timeout = 0;

$('.nick-check').on('keypress keyup focus', function () {
  function nick_check_indicator_change(classes) {
    $('#nick-check-indicator').removeClass(
      'fa-spin fa-lock fa-times fa-check fa-spinner fa-question'
    );
    $('#nick-check-indicator').addClass(classes);
  }

  $(this).removeClass('has-error');
  $(this).parent().removeClass('has-error');
  clearTimeout(nick_check_timeout);

  var nick = $(this).val();

  nick = nick.replace(/&/g, 'and');
  nick = nick.replace(/\s+/g, ' ');
  nick = nick.replace(/[^a-zA-Z 0-9]+/g, '');

  $(this).val(nick);
  var url = nick.replace(/ /g, '-').toLowerCase();

  $('.web-address').html('https://www.travelblog.org/bloggers/' + url);

  if (nick === ' ' || nick === '') {
    $(this).val('');
    nick_check_indicator_change('fa-question');
    $('#nick-check-error-msg').html('');
    $(this).addClass('has-error');
    return;
  }

  var original = $(this).data('original');
  if (nick === original) {
    nick_check_indicator_change('fa-lock');
    $(this).removeClass('has-error');
    $('#nick-check-error-msg').html('');
    return;
  }

  nick_check_timeout = setTimeout(function () {
    if (nick === '') {
      return;
    }

    $('#nick-check-error-msg').removeClass('hide');
    if (nick.length < 3) {
      nick_check_indicator_change('fa-times');
      $('.nick-check').addClass('has-error');
      $('#nick-check-error-msg').html(
        '- your nickname must be longer than 2 letters'
      );
      return;
    }

    $('#nick-check-error-msg').html('- checking...'); // checking...

    $.post('/ajax/nick-check', { nick: nick }, function (data) {
      if (data === '-1') {
        // nick available
        nick_check_indicator_change('fa-check');
        $('#nick-check-error-msg').html('- nickname available!');
      } else {
        // nick taken
        $('.nick-check').addClass('has-error');
        nick_check_indicator_change('fa-times');
        $('#nick-check-error-msg').html(
          '- sorry someone else has this nickname'
        );
      }
    });
  }, 500);

  $('#nick-check-error-msg').html('- typing...'); // checking...
  nick_check_indicator_change('fa-spinner fa-spin');
});

$('#join_email').on('keypress keyup focus blur', function () {
  $('.form-group.email').removeClass('has-error');
  var email = $('#join_email').val();
  $('#join_email').removeClass('has-error').removeClass('has-success');
  if (email.length < 2) {
    return;
  }
  if (email.length < 5) {
    $('#join_email').addClass('has-error');
    $('#join_email_error_msg').html('');
    return;
  }

  if (email.indexOf('@') === -1 || email.indexOf('.') === -1) {
    $('#join_email').addClass('has-error');
    return;
  }

  $('#join_email').addClass('has-success');
});

$('#join_email2').on('keypress keyup focus blur', function () {
  var email = $('#join_email2').val();
  $('#join_email').removeClass('has-error').removeClass('has-success');
  if (email.length < 2) {
    return;
  }
  if (email.length < 5) {
    $('#join_email2').addClass('has-error');
    return;
  }

  if (email.indexOf('@') === -1 || email.indexOf('.') === -1) {
    $('#join_email2').addClass('has-error');
    return;
  }

  var email1 = $('#join_email').val();

  if (email1 !== email) {
    $('#join_email2').addClass('has-error');
    return;
  }

  $('#join_email2').addClass('has-success');
});

$('.email').emailOnly();
$('.email').on('keypress keyup focus blur', function () {
  var $el = $(this);
  var email = $(this).val();
  $el.removeClass('has-error has-success');
  $el.parent().removeClass('has-error');
  if (email.length < 2) {
    return;
  }
  if (email.length < 5) {
    $el.addClass('has-error');
    return;
  }

  if (email.indexOf('@') === -1 || email.indexOf('.') === -1) {
    $el.addClass('has-error');
    return;
  }
  $el.addClass('has-success');
});

$('.match').on('keypress keyup focus blur', function () {
  var $el = $(this);
  $el.removeClass('has-error has-success');
  $el.parent().removeClass('has-error');
  var $match = $('#' + $el.data('match'));
  var $indicator = $('#indicator-' + $el.data('match'));

  if ($el.val() === $match.val()) {
    $el.addClass('has-success');
    $indicator.removeClass('fa-times');
    $indicator.addClass('fa-check');
  } else {
    $el.addClass('has-error');
    $indicator.removeClass('fa-check');
    $indicator.addClass('fa-times');
  }
});

$('.check-form').on('click', function (e) {
  e.preventDefault();
  var $form = $('form#' + $(this).data('form'));
  var hasError = false;
  $form.find('input').each(function () {
    if ($(this).hasClass('has-error')) {
      hasError = true;
      $(this).parent().addClass('has-error');
    }
  });

  if (!hasError) {
    $form.submit();
  }
});

module.exports.passwordLock = function passwordLock() {
  $('#password-lock').removeClass('fa-unlock fa-spin fa-spinner');
  $('#password-lock').addClass('fa-lock');
  $('.account-settings').css('opacity', '0.6');
  $('.account-settings select').prop('disabled', true);
  $('.account-settings input').prop('disabled', true);
  $('#submitButton').prop('disabled', true);
};

module.exports.passwordUnlock = function passwordUnlock() {
  $('#password-lock').removeClass('fa-lock fa-spin fa-spinner');
  $('#password-lock').addClass('fa-unlock');
  $('.account-settings').css('opacity', '1');
  $('.account-settings select').removeAttr('disabled');
  $('.account-settings input').prop('disabled', false);
  $('#submitButton').prop('disabled', false);
};

var passcheckTimer;
$('#current-password').on('keypress keyup blur', function () {
  clearTimeout(passcheckTimer);

  var pass = $(this).val();
  if (pass === '') {
    module.exports.passwordLock();
  } else {
    $('#password-lock').removeClass('fa-lock fa-unlock');
    $('#password-lock').addClass('fa-spin fa-spinner');
    // show spinner...
    passcheckTimer = setTimeout(function () {
      $.ajax({
        method: 'post',
        url: '/admin/ajax/pass-check',
        data: {
          password: pass
        },
        success: function (data) {
          if (data === true) {
            module.exports.passwordUnlock();
          } else {
            module.exports.passwordLock();
          }
        }
      });
    }, 500);
  }
});
