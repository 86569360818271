'use strict';
var Mustache = require('mustache');

$(function () {
	$(document).on('click', '#search-users', function (e) {
		e.preventDefault();
		$.ajax({
			url: '/ajax/people-search',
			cache: false,
			data: {
				nick: $('#search-name').val()
			}
		}).done(function (data) {
			if (data && typeof data !== 'object') {
				data = $.parseJSON(data);
			}
			if (typeof data === 'object') {
				var html = render_users(data, 'private-invite');
				$('#search-results').html(html);
			} else {
				console.log(
					'data not an object, JSON not returned via ' + this.url
				);
			}
		});
	});

	$(document).on('click', 'a.private-invite', function (e) {
		var id = $(this).data('user-id'); // this is toId!
		var fromId = parseInt($.cookie('user_id'));

		e.preventDefault();

		var img = $('#user-' + id).data('src');
		$('#invite-modal-preview').attr('src', img);
		var name = $('#user-' + id).data('name');
		$('#invite-modal-name').html(name);
		var nick = $('#user-' + id).data('nick');
		$('#invite-modal-nick').html(nick);

		console.log('Inviting:', img, nick, name);
		$('#inviteModalConfirm').on('click', function () {
			$.ajax({
				url: '/admin/ajax/private',
				cache: false,
				data: {
					from_id: fromId,
					to_id: id,
					action: 'invite'
				}
			}).done(function (data) {
				if (data && typeof data !== 'object') data = $.parseJSON(data);
				if (!data.success) {
					// toaster time - when doing angular2.
					console.log('FAILED:', data);
				}
				$('#inviteModal').modal('hide');
			});
		});
		$('#inviteModal').modal();
	});

	// modal example.
	$(document).on('click', 'a.confirm-invite', function (e) {
		var id = $(this).data('user-id');
		e.preventDefault();

		var title = $('#privacy-' + id + '-title').text();
		var caption = $('#privacy-' + id + '-caption').text();
		var imgsrc = $('#img-' + id).attr('src');
		$('#edit-modal-preview').attr('src', imgsrc);
		$('#edit-modal-title').val(title);
		$('#edit-modal-caption').val(caption);
		$('#editModalSave').off('click');
		$('#editModalSave').on('click', function () {
			$('#editModal').modal('hide');
		});
		$('#inviteModal').modal();
	});

	function render_users(data, itemClass) {
		var html = '';
		var tpl = $('#_user_item').html();

		for (var key in data) {
			var user = data[key];
			user.class = itemClass;
			html += Mustache.to_html(tpl, user);
		}
		return html;
	}
});
