"use strict";

$.fn.extend({

  emailOnly: function() {
    return this.each(function() {
      return $(this).keypress(function(e, text) {
        var keynum;
        var allowedKeyNums = [8, 9, 35, 36, 37, 39, 46]; // Backspace, Tab, End, Home, (Delete & period)

        if (window.event) // IE
            keynum = e.keyCode;
        else if (e.which) // Netscape/Firefox/Opera
            keynum = e.which;
        else
            keynum = e.keyCode;

        var keychar = String.fromCharCode(keynum);
        var regEx = /[^$#<>?]/; // Undesirable characters

        // Test for keynum values that collide with undesirable characters
        if ($.inArray(keynum, allowedKeyNums) > -1)
          return regEx.test(keychar);

        regEx = /[A-Z0-9a-z@\+\-\_]/;
        return regEx.test(keychar);
      });
    });
  }

});
