"use strict";

module.exports = {};

module.exports.doLazy = function doLazy() {
  $('img.lazy').lazyload({
    failure_limit : 100,
    threshhold: 200
  });
  $('.image.lazy').lazyload({
    failure_limit : 100,
    threshhold: 200
  });
};

module.exports.load_tb_tabs = function load_tb_tabs(url, target, self, default_content){
  var this_li = self.parent();

  if(url){
    $.ajax({
      type : 'GET',
      url : url,
      success:function(content){
        $(target).fadeOut(200, function() {
          $(target).html(content).fadeIn(500, function(){ module.exports.doLazy();});
          this_li.removeClass('loading');
          this_li.addClass('active');
          this_li.focusout();
        });
      },
      failure:function(){
        $(target).html(default_content);
        $(this_li).removeClass('loading');
        $(this_li).addClass('active');
      }
    });
  } else{
    $(target).html(default_content);
    $(this_li).removeClass('loading');
    $(this_li).addClass('active');
    module.exports.doLazy();
  }
};

module.exports.ready = function ready(){
  $('a.tb-tab').on('click',function(e){
    e.preventDefault();
    module.exports.tab_load(this);
  });
};


module.exports.tab_load = function tab_load(tab){
  var self = $(tab);
  var tabs = self.parent().parent().children();
  var tab_container = self.parent().parent().parent();

  var is_loading = tabs.find('li.loading').length;

  if (is_loading === 0) {

    tabs.removeClass('active');

    self.addClass('loading');

    var tab_content_url = self.data('tab_content_url');

    var tab_id = tab_container.data('target');
    var wrapper_id = 'div#' + tab_id;

    module.exports.load_tb_tabs(tab_content_url,wrapper_id,self,'');
  }
};
