'use strict';

var Mustache = require('mustache');
var utils = require('./utils');

module.exports = {};

module.exports.childRedirect = function childRedirect(url) {
	// used by forums

	url = window.location.protocol + '//' + window.location.host + url;

	window.top.location.href = url;
	if (window.top.location.href === url) {
		window.top.location.reload();
	}

	return false;
};

module.exports.submit_search_link = function submit_search_link() {
	var q = $('#search-link').data('q');
	$('#search-top').val(q);
	$('#submit-search-top').click();
};

module.exports.cms_data = function cms_data() {
	// finds cms stuff and do replace...

	$('span.cms_age').each(function () {
		var utime = $(this).attr('data-value');
		var today = new Date();
		var now = Math.round(today.getTime() / 1000.0);
		var age = now - utime;
		if (age < 0) age = 0;
		var age_text = '';
		if (age < 600) age_text = 'Age:' + age + 's';
		else if (age >= 6000) {
			var hours = age / 60 / 60;
			age_text = 'Age:' + Math.floor(hours) + 'h';
		} else if (age >= 600) {
			var mins = age / 60;
			age_text = 'Age:' + Math.floor(mins) + 'm';
		}
		$(this).html(age_text);
	});

	$('span.gmap_edit_link').each(function () {
		var this_user_id = parseInt($.cookie('user_id'));
		var map_owner_id = parseInt($(this).attr('data-owner'));
		var map_id = $(this).attr('data-map-id');
		var blog_id = $(this).attr('data-blog-id');
		if (this_user_id !== map_owner_id) return;

		$(this).html(
			"[<a href='/admin/blog/gmap-entry?map_id=" +
				map_id +
				'&amp;diary_id=' +
				blog_id +
				"' style='color:#fa0;'>Edit This Map</a>]"
		);
	});

	$('.cms-logout').on('click', function (e) {
		e.preventDefault();
		$.ajax({
			type: 'GET',
			url: '/ajax/logout',
			success: function () {
				window.location.replace('/');
			},
			error: function () {
				document.location.replace('/admin/logout');
			}
		});
	});

	$('.print-link').on('click', function (e) {
		e.preventDefault();
		var user_id = parseInt($.cookie('user_id'), 10);
		if (user_id < 1) return;
		window.open($(this).data('dst'));
	});

	$('#private-blogs').each(function () {
		var user_id = parseInt($.cookie('user_id'));
		var trust = parseInt($.cookie('cms_trust'));
		if (isNaN(trust) || isNaN(user_id)) return;
		if (trust >= '8') return $(this).show();

		var invitees = $(this).data('invitees');
		if (window.jQuery.inArray('' + user_id, invitees) !== -1) {
			$(this).removeClass('hide');
		}
	});

	$('img.cms_counter').each(function () {
		$(this).show(); // simplest
	});
	//<span class='countdown_clock' data-year='12' data-month='12' data-day='25' data-hour='00' data-min='00' data-text='Until Christmas'>

	$('div.countdown').each(function () {
		$.fn.counter(this, true);
	});
	$('div.countup').each(function () {
		$.fn.counter(this, false);
	});
	$('#forum_search').each(function () {
		var user_id = parseInt($.cookie('user_id'));
		if (isNaN(user_id)) return;
		$(this).show();
	});
	$('#forum_new').each(function () {
		var user_id = parseInt($.cookie('user_id'));
		if (isNaN(user_id)) return;
		$(this).show();
	});

	function show_rating(rate) {
		for (var i = 1; i <= 5; i++) {
			$('#star-' + i).removeClass('fa-star');
			$('#star-' + i).removeClass('fa-star-o');
			if (rate < i) {
				$('#star-' + i).addClass('fa-star-o');
			} else {
				$('#star-' + i).addClass('fa-star');
			}
		}
	}

	function update_rating(id, rate, callback) {
		$.get('/ajax/photo?id=' + id + '&rating=' + rate, function (data) {
			callback(data);
		});
	}

	$('#photo-rating li')
		.on('mouseenter', function () {
			show_rating($(this).data('rate'));
		})
		.on('mouseout', function () {
			show_rating($('#photo-rating').data('rating'));
		})
		.on('click', function (e) {
			e.preventDefault();
			var rate = $(this).data('rate');
			var id = $('#photo-rating').data('photo');
			$('#photo-rating').addClass('small').html('thanks for voting');
			update_rating(id, rate, function (data) {
				/*global next_url:true, next_photo:true */
				if (next_url && next_url !== '') {
					setTimeout(function () {
						window.location = next_url;
					}, 1000);
				} else if (next_photo && next_photo !== '') {
					setTimeout(function () {
						window.location = next_photo;
					}, 1000);
				}
			});
		});

	function follow(e) {
		/*jshint validthis:true */
		e.preventDefault();
		var $self = $(this);
		var $icon = $(this).find('i');
		$.ajax({
			url: '/admin/ajax/follow',
			type: 'POST',
			data: {
				blogger_id: $(this).data('blogger'),
				action: 'follow'
			},
			success: function (data) {
				$self.off('click').on('click', unfollow);
				$self.attr('title', 'click to follow');
				$icon
					.removeClass('fa-star-o text-muted')
					.addClass('fa-star text-success');
			}
		});
	}

	function unfollow(e) {
		/*jshint validthis:true */
		e.preventDefault();
		var self = $(this);
		var icon = $(this).find('i');
		$.ajax({
			url: '/admin/ajax/follow',
			type: 'POST',
			data: {
				blogger_id: $(this).data('blogger'),
				action: 'unfollow'
			},
			success: function (data) {
				self.off('click').on('click', follow);
				self.attr('title', 'click to unfollow');
				icon.removeClass('fa-star text-success').addClass(
					'fa-star-o text-muted'
				);
			}
		});
	}

	$.extend($.tablesorter.themes.bootstrap, {
		table: 'table',
		caption: 'caption',
		header: '',
		footerRow: '',
		footerCells: '',
		icons: 'fa',
		sortNone: 'fa-sort',
		sortAsc: 'fa-sort-asc',
		sortDesc: 'fa-sort-desc',
		active: '',
		hover: '',
		even: '',
		odd: ''
	});

	$('table.table-sorter').tablesorter({
		theme: 'bootstrap',
		widthFixed: false,
		headerTemplate: '{content} {icon}',
		widgets: ['uitheme', 'zebra'],
		widgetOptions: {
			zebra: ['even', 'odd'],
			filter_reset: '.reset'
		}
	});

	$('.admin .follow').on('click', follow);
	$('.admin .unfollow').on('click', unfollow);

	// Datepicker icons... note need a for.
	$('.datepicker-icon').on('click', function (e) {
		e.preventDefault();
		var input = $('#' + $(this).data('for') + ' input');
		input.trigger('focus');
	});

	function forumSubToggle(e) {
		/*jshint validthis:true */
		e.preventDefault();
		var icon = $(this).find('i');
		$.ajax({
			url: '/admin/ajax/forum-sub-toggle',
			type: 'POST',
			data: {
				forum_id: $(this).data('forum-id'),
				topic_id: $(this).data('topic-id'),
				action: 'toggle'
			},
			success: function (data) {
				if (data.subscribed) {
					icon.removeClass('fa-times text-muted').addClass(
						'fa-check text-success'
					);
				} else {
					icon.removeClass('fa-check text-success').addClass(
						'fa-times text-muted'
					);
				}
			}
		});
	}
	$('.forum-sub-toggle').on('click', forumSubToggle);
};

var admin_section = false;
if (utils.info.section === 'admin') admin_section = true;

if (admin_section) {
	var backup_to;
	$(document).on('ready', function () {
		backup_to = setTimeout(module.exports.display_backup_warning, 10000);
	});
}

module.exports.display_backup_warning = function display_backup_warning() {
	// if admin!
	var st = $('#servertime').val();
	var now = new Date(st * 1000); // * 1000 to get a javascript date!
	var hour = now.getUTCHours();
	var min = now.getUTCMinutes();

	// NOTE HARDCODED!
	if (hour === 5 && min >= 25 && min <= 35) {
		$('#jquery_message').html(
			'Site down time very soon 05:29 - 05:35 UTC - save your work!'
		);
		$('#jquery_message').removeClass('hidden');
	} else {
		$('#jquery_message').addClass('hidden');
	}
	backup_to = setTimeout(module.exports.display_backup_warning, 10000);
};

module.exports.setCookie = function setCookie(name, value, days) {
	var expires;
	if (days) {
		var date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		expires = '; expires=' + date.toGMTString();
	} else expires = '';
	document.cookie = name + '=' + value + expires + '; path=/';
};

// deprecate
module.exports.gotoPage = function gotoPage(page) {
	top.location = page;
};

// deprecate
module.exports.gotoForum = function gotoForum(dir) {
	top.location = '/Forum' + dir;
};

module.exports.anonUser = function anonUser() {
	$('body').addClass('not-logged-in');
	$('.user-show .admin-show .moderator-show .anon-hide').hide();
	return;
};

module.exports.loggedIn = function loggedIn() {
	var user_id = utils.getCookie('user_id');

	if (!user_id) return module.exports.anonUser(); // just hide stuff

	var nick = utils.getCookie('tb_nick');
	var blog_url = decodeURIComponent(utils.getCookie('cms_url'));

	$('.user-blog-link').attr('href', blog_url);

	var display_nick = decodeURI(nick.replace(/\+/g, ' '));
	$('.user-name').html(display_nick);
	$('.user-blog').html(blog_url);
	$('body').addClass('logged-in');

	$('span.missing').show(); // here get trust level and display if allowed to edit.

	var timeout;
	timeout = setTimeout(function () {
		top.location = '/admin/logout';
	}, 180 * 60000); // 3 hours

	var trust = parseInt(utils.getCookie('cms_trust'), 10);
	if (trust >= 8) $('body').addClass('moderator-user');
	if (trust === 10) $('body').addClass('admin-user');

	if ($('body').data('owner')) {
		var owner = $('body').data('owner');
		if (parseInt(owner, 10) === parseInt(user_id, 10)) {
			$('body').addClass('owner');
		}
	}

	$('.follow.btn').each(function () {
		var user_id = parseInt($.cookie('user_id'));
		if (isNaN(user_id)) {
			$(this).hide();
			return;
		}
		$(this).show();

		if (user_id === $(this).data('to-user-id')) {
			$('.follow').prop('disabled', true);
			$('.follow').removeClass('btn-primary');
			$('.follow').addClass('disabled btn-default');
			$('.tb-follow').removeClass('fa-star-o').addClass('fa-star');
			return;
		}

		$.ajax({
			type: 'POST',
			url: '/admin/ajax/follow',
			data: {
				blogger_id: $(this).data('to-user-id'),
				action: 'get'
			},
			success: function (data) {
				if (data.follows) {
					$('.follow')
						.removeClass('btn-primary')
						.addClass('btn-default');
					$('.follow').prop('title', 'You Follow');
					$('.tb-follow')
						.removeClass('fa-star-o')
						.addClass('fa-star');
				}
			}
		});
	});

	$('.follow').on('click', function (e) {
		e.preventDefault();
		$.ajax({
			type: 'POST',
			url: '/admin/ajax/follow',
			data: {
				blogger_id: $(this).data('to-user-id'),
				action: 'toggle'
			},
			success: function (data) {
				var count;
				if (data.follows) {
					$('.follow').each(function () {
						$(this).removeClass('btn-primary');
						$('.icon-follow')
							.removeClass('fa-star-o')
							.addClass('fa-star');
						$(this).prop('title', 'Click to Unfollow');
						count = $(this).data('follower-count');
						$(this).data('follower-count', count + 1);
						$('.follower-count').html(count + 1);
					});
				} else {
					$('.follow').each(function () {
						$(this).addClass('btn-primary');
						$('.icon-follow')
							.removeClass('fa-star')
							.addClass('fa-star-o');
						$(this).prop('title', 'Click to Follow');
						count = $(this).data('follower-count');
						$(this).data('follower-count', count - 1);
						$('.follower-count').html(count - 1);
					});
				}
			},
			error: function (e, data) {
				console.log(e, data);
			}
		});
	});
};

var session_timeout;
module.exports.setSessionTimeout = function setSessionTimeout(mins) {
	mins = typeof mins !== 'undefined' ? mins : 60;
	session_timeout = setTimeout(function () {
		top.location = '/admin/logout';
	}, mins * 60000); // 60000 microsecs = 1 min
};

module.exports.session_end = function session_end() {
	module.exports.gotoPage('/admin/logout');
};

var panos;
var pano_pos;
module.exports.panoramas_get = function panoramas_get(blog_id) {
	$.ajax({
		url: '/ajax/panorama-list',
		method: 'GET',
		data: {
			blog_id: blog_id
		},
		cache: false
	}).done(function (data) {
		if (typeof data !== 'object') data = $.parseJSON(data);
		panos = data; // global
		pano_pos = 0;

		var max_height = 0;
		var max_width = 0;
		var max_ratio = 0;
		$(panos).each(function () {
			var ratio = this.height / this.width;
			if (ratio > max_ratio) {
				max_height = this.height;
				max_width = this.width;
				max_ratio = ratio;
			}
		});

		// need to now get the width of the page and do a ratio...
		var ratio = max_height / max_width;
		var avail_width = $('#panorama-wrapper').width();
		var set_height = avail_width * max_ratio;
		if (set_height > 400) set_height = 400;

		$(panos).each(function () {
			ratio = avail_width / this.width;
			this.set_height = this.height * ratio;
			this.set_width = this.width * ratio;
		});

		$('#panorama-container').animate(
			{
				height: set_height
			}, // page width / factor
			1000,
			function () {
				//setup next.
				$('div#panorama-wrapper').height(set_height);
				var count = panos.length;
				if (count > 1) module.exports.render_panorama(panos[pano_pos]);
				else {
					module.exports.render_single_panorama(panos[0]);
				}
			}
		);
	});
	return;
};

module.exports.render_single_panorama = function render_single_panorama(data) {
	var tpl = $('#_pano_item').html(); // get content of text/plain node

	$('#panorama-container').html(Mustache.render(tpl, data));
	$('#img-pano-' + data.id).height(data.set_height);
	$('#img-pano-' + data.id).width(data.set_width);
	$('#pano-' + data.id).fadeIn(1000);
};

module.exports.render_panorama = function render_panorama(data) {
	module.exports.render_single_panorama(data);
	var last_id = data.id;
	var next;
	if (panos.length > 1) {
		pano_pos = (pano_pos + 1) % panos.length;
		next = setTimeout(function () {
			$('div#pano-' + last_id).fadeOut(400, function () {
				render_panorama(panos[pano_pos]);
			});
		}, 8000);
	}
};

module.exports.photo_navigation = function photo_navigation(
	vals,
	id,
	base,
	qs,
	show
) {
	if (!vals) return;

	id = id.toString();
	var pos = vals.indexOf(id);

	var prev_id = 0,
		next_id = 0,
		i = 0,
		low = pos - show,
		high = pos + show;

	var count = vals.length;

	if (low < 0) high = high - low;

	if (high > count) low = low - (high - count);

	var pager = '';
	var found = false;
	var last = false;
	var skipping = false;
	var v_id = 0;
	var display = 0;

	for (i = 0; i < count; i++) {
		v_id = vals[i];
		display = i + 1;

		if (v_id === id) {
			pager += "<li class='active'><a href='#'>" + display + '</a>';
		} else if (i <= low || i >= high) {
			if (skipping === false) {
				pager += "<li class='disabled'><a href='#'>...</a>";
			}
			skipping = true;
		} else {
			pager +=
				"<li><a href='" + base + v_id + qs + "'>" + display + '</a>';
			skipping = false;
		}

		if (found && !next_id) {
			next_id = v_id;
		}

		if (v_id === id) {
			found = true;
			prev_id = last;
		}

		last = v_id;
	}

	var next_link = '',
		prev_link = '';

	if (i) {
		if (next_id === 0) {
			next_link =
				"<li class='disabled'><a href='#'>&raquo;</a><li class='disabled'><a href='#'>&raquo;&raquo;</a>";
		} else if (id === next_id) {
			next_link =
				"<li class='disabled'><a href='#'>&raquo;</a><li><a href='" +
				base +
				vals[count] +
				qs +
				"' title='Last'>&raquo;&raquo;</a>";
		} else {
			next_link =
				"<li><a href='" +
				base +
				next_id +
				qs +
				"' title='next' class='nextphoto'>&raquo;</a><li><a href='" +
				base +
				v_id +
				qs +
				"' title='Last'>&raquo;&raquo;</a>";
		}
		if (prev_id === 0) {
			prev_link =
				"<li class='disabled'><a href='#'>&laquo;&laquo;</a><li class='disabled'><a href='#'>&laquo;</a>";
		} else if (id === prev_id) {
			prev_link =
				"<li><a href='" +
				base +
				prev_id +
				qs +
				"' title='First'>&laquo;&laquo;</a><li><a href='#'>&laquo;</a>";
		} else {
			prev_link =
				"<li><a href='" +
				base +
				vals[0] +
				qs +
				"' title='First'>&laquo;&laquo;</a><li><a href='" +
				base +
				prev_id +
				qs +
				"' title='Previous' class='backphoto'>&laquo;</a>";
		}

		pager = prev_link + pager + next_link;
	}

	return pager;
};

// RUN FUNCTIONS

module.exports.init = function () {};

module.exports.ready = function ready() {
	module.exports.loggedIn();

	$('.email').emailOnly();

	if ($('body').hasClass('blog')) {
		var blog_id = $('body').data('blog');
		$.get('/ajax/tb-count?type=json&blog=' + blog_id);
	}

	// if certain cookies then show stuff - like the forum.
	var trust = parseInt($.cookie('cms_trust'), 10);

	if (trust >= 8) $('div.forum_control').css('display', 'block');
	if (trust >= 8) $('div.forum_post span.control').css('display', 'inline');
	var user_id = $.cookie('user_id');

	$('span.owner_edit[data-user-id=' + user_id + ']').show();

	if ($('#back_url').val() !== undefined) {
		var back_url = document.location.href;
		$('#back_url').val(back_url);
	}

	if ($('#form_security').val() !== undefined) {
		var key = $.md5('blahblah');
		$('#form_security').val(key); // do something complicated and annoying here - maybe go off and do ajax stuff.
	}

	$('div.blogger_mini_pic img').jrumble({
		rumbleSpeed: 0,
		rangeX: 0,
		rangeY: 0.1,
		rangeRot: 0.2
	});

	$('img.lazy').lazyload({
		failure_limit: 100,
		threshhold: 200,
		effect: 'show'
	});
	$('.image.lazy').lazyload({
		failure_limit: 100,
		threshhold: 200,
		effect: 'show'
	});

	if ($('div#panorama-container').length !== 0) {
		var id = $('div#panorama-container').data('blog-id');
		module.exports.panoramas_get(id);
	}

	if ($('.moderate-photo').length !== 0 && $.cookie('user_id') > 0) {
		$.ajax({
			url: '/admin/ajax/url-get-flag',
			success: function (data) {
				if (data.flag) {
					$('.moderated-' + data.flag).addClass('selected');
				}
			}
		});

		$('.moderate-photo').on('click', function (e) {
			e.preventDefault();
			var flag = $(this).data('flag');
			$.ajax({
				url: '/admin/ajax/url-flag?flag=' + flag,
				success: function () {
					$('.moderate-photo').removeClass('selected');
					$('.moderated-' + flag).addClass('selected');
				}
			});
		});
	}

	$('.parallax').each(function () {
		var $bgobj = $(this);

		$(window).scroll(function () {
			var yPos = -($(window).scrollTop() / $bgobj.data('speed'));

			// Put together our final background position
			var coords = '50% ' + yPos + 'px';

			// Move the background
			$bgobj.css({
				backgroundPosition: coords
			});
		});
	});

	$('.invite-btn').on('click', function (e) {
		e.preventDefault();

		var id = $(this).data('invite-id');
		var action = $(this).data('action');
		var href = $(this).data('href');

		$.ajax({
			url: '/admin/ajax/private',
			type: 'POST',
			data: {
				action: action,
				invite_id: id
			},
			success: function (data) {
				console.log(data.result);
				if (data.result.state === 'accept')
					$('.invite-panel-' + id + ' .buttons').html(
						'<a href="' + href + '">accepted -  view here</a>'
					);
				else
					$('.invite-panel-' + id).fadeOut(200, function () {
						$(this).hide(200);
					});
			}
		});
	});

	$('a.subscribe-btn').on('click', function (e) {
		e.preventDefault();

		$('#subscribeModal').modal();
	});

	$('#subscribeSubmit').on('click', function (e) {
		e.preventDefault();

		$('#subscribeSubmit').prop('disabled', true);
		var email1 = $('#sub-email1').val();
		var email2 = $('#sub-email2').val();
		var name = $('#sub-name').val();

		$('#sub-name').removeClass('has-error');
		if (!name.length) {
			$('#sub-name').addClass('has-error');
			$('#subscribe-msg')
				.html('Name is required')
				.addClass('text-error')
				.removeClass('hidden');
			$('#subscribeSubmit').prop('disabled', false);
			return;
		} else {
			$('#sub-name').removeClass('has-error');
			$('#subscribe-msg')
				.html('')
				.removeClass('text-error')
				.addClass('hidden');
		}

		if (
			email1.indexOf('@') === -1 ||
			email1.indexOf('.') === -1 ||
			email1 !== email2
		) {
			$('#sub-email2').addClass('has-error');
			$('#subscribe-msg')
				.html('emails must match and be valid')
				.addClass('text-error')
				.removeClass('hidden');
			$('#subscribeSubmit').prop('disabled', false);
			return;
		} else {
			$('#sub-email2').removeClass('has-error');
			$('#subscribe-msg')
				.html('')
				.removeClass('text-error')
				.addClass('hidden');
		}

		var blogger_id = $('.subscribe-btn').data('blogger-id');
		if (!blogger_id) return;

		$.ajax({
			type: 'POST',
			url: '/ajax/subscribe',
			data: {
				name: name,
				email: email1,
				blogger_id: blogger_id, // TODO - populate when opened.
				action: 'subscribe'
			},
			success: function (data) {
				if (data.success) {
					$('#subscribe-msg')
						.html('You have subscribed')
						.removeClass('hidden');
					setTimeout(function () {
						$('#subscribeModal').modal('toggle');
					}, 1000);
				} else {
					$('#subscribe-msg')
						.html(data.message)
						.addClass('text-error')
						.removeClass('hidden');
				}
			},
			error: function (data) {
				$('#subscribe-msg')
					.html('something went wrong')
					.addClass('text-error')
					.removeClass('hidden');
				$('#subscribeSubmit').prop('disabled', false);
			}
		});
	});

	$('#sub-email2, #sub-email1').on('keypress keyup focus blur', function (e) {
		var email2 = $('#sub-email2').val();
		var email1 = $('#sub-email1').val();
		var email = $(this).val();

		$('#sub-email2, #sub-email1')
			.removeClass('has-error')
			.removeClass('has-success');

		if (email.length < 2) return;
		if (email.length < 5) {
			$(this).addClass('has-error');
			return;
		}

		if (email.indexOf('@') === -1 || email.indexOf('.') === -1) {
			$(this).addClass('has-error');
			return;
		}

		if (email1 !== email2) {
			$('#sub-email2').addClass('has-error');
			return;
		}

		$(this).addClass('has-success');
	});

	module.exports.cms_data();
};

module.exports.interacted = function () {
	// here - things that need to be done once the page is interacted with
};
