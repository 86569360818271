'use strict';

/* jshint devel:true*/
/* global $:true, window:true _:true*/

if (typeof window.jQuery === 'undefined')
	var $ = (window.jQuery = window.$ = require('jquery'));

var _ = (window._ = require('underscore'));
require('./jquery-load.js');

var cms = require('./src/cms');
var account = require('./src/account');
var ready = require('./src/ready');
var utils = require('./src/utils');
var forum = require('./src/forum');
var admin = require('./src/admin');
var privacy = require('./src/privacy');
var tabs = require('./src/tabs');
var multiselect = require('./src/multiselect');
var countdown = require('./src/countdown');
var cookieMsg = require('./src/cookie-msg');

var _app = {
	account: account,
	utils: utils,
	cms: cms,
	ready: ready,
	admin: admin,
	tabs: tabs,
	forum: forum,
	multiselect: multiselect,
	countdown: countdown,
	cookieMsg: cookieMsg,
	privacy: privacy,

	// internal vars
	_interacted: false
};

window._app = _app; // make globally available

var _vendor = {};
_vendor.shortcut = require('./vendor/key-shortcut.js'); // TO DO - vendor-index?

window._vendor = _vendor; // make vendor scripts globally available

if (!window._page) window._page = {};
// AUTOMATICALLY RUN APPROPRIATE STUFF

// Expect module format
// - init() run automatically when loaded,
// - ready() run when document ready
// - interacted() run when user interacts, focuses, scrolls etc

// INIT
var mod;
var i;
for (i in _app) {
	mod = _app[i];
	if (typeof mod.init === 'function') {
		mod.init();
	}
	// create on page functions as _page
	if (typeof window._page.init === 'function') window._page.init();
}

// READY
$(function () {
	var mod;
	var i;
	for (i in _app) {
		mod = _app[i];
		if (typeof mod.ready === 'function') {
			mod.ready();
		}
	}
	// create on page functions as _page
	if (typeof window._page.ready === 'function') window._page.ready();
});

// INTERACTED
$('body').on(
	'mouseenter mouseover mousemove click focus scroll touchstart',
	function () {
		if (window._app._interacted === true) return;

		var mod;
		var i;
		for (i in _app) {
			mod = _app[i];
			if (typeof mod.interacted === 'function') {
				mod.interacted();
			}
		}

		// create on page functions as _page
		if (typeof window._page.interacted === 'function')
			window._page.interacted();

		$('body').off(
			'mouseenter mouseover mousemove click focus scroll touchstart'
		); // stop listening.
	}
);
