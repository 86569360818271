"use strict";

module.exports = {};

var cookieMsg = function cookieMsg() {
  // candidates - ['AL', 'ME', 'IS', 'RS', 'TR'] - for reference later.
  var eu = [
    'BE', 'BG', 'CZ', 'DK', 'DE', 'EE', 'IE', 'EL', 'ES', 'FR',
    'HR', 'IT', 'CY', 'LV', 'LT', 'LU', 'HU', 'MT', 'NL', 'AT',
    'PL', 'PT', 'RO', 'SI', 'SK', 'FI', 'SE', 'UK',
    'GB', 'GE' // united kingdom and greece iso-1336 code.
    // 'UK', 'EL' // eu varients on iso-3166 - not returned by geoip.
  ];

  var cc = $('body').data('country');

  if (eu.indexOf(cc) === -1 || $.cookie('dumb-cookie-message')) {
    // do nothing we're outside of europe - or they've seen the message and clicked got it.
    return;
  }
  $('body').append('<div id="dumb-cookie-message" style="position:fixed;' +
    'padding:15px 5%;background:rgba(255,255,255,0.9);top:0px;left:0px;width:100%;z-index:9999;border-bottom:1px solid #fff;">' +
    'We use cookies to personalise content and ads, to provide social media features and to analyse our traffic.' +
    ' We also share information about your use of our site with our social media, advertising and analytics partners. <a href="/privacy.html">More Details</a>' +
    '<div class="center" style="padding:5px"><button id="cookie-dismiss" class="btn btn-default">Hide This Message</button></div></div>');
  $('body').css('padding-top', $('#dumb-cookie-message').outerHeight() + 'px');

  $('button#cookie-dismiss').on('click', function() {
    $('body').css('padding-top', '0px');

    $.cookie('dumb-cookie-message', 'hide', {
      expires: 365
    });
    $('#dumb-cookie-message').hide();
  });
};

module.exports.ready = cookieMsg;
